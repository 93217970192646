<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 节目名称 -->
					<el-form-item label="节目名称" prop="title">
						<div class="aaaa">
							<el-input v-model="form.title" placeholder="请填写节目名称" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 部门名称 -->
					<el-form-item label="部门名称" prop="department">
						<div class="aaaa">
							<el-input v-model="form.department" placeholder="请填写部门名称" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 是否发布 -->
					<el-form-item label="是否发布" prop="isEnable">
						<el-select v-model="form.isEnable" placeholder="请选择是否发布" size="medium">
							<el-option label="发布" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="未发布" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 排序 -->
					<el-form-item label="排序" prop="sort">
						<div class="aaaa">
							<el-input v-model="form.sort" type="number" placeholder="请填写排序值" size="large"></el-input>
						</div>
						<div class="referrals">
							（提示：大到小排序 默认100）
						</div>
					</el-form-item>

					<!-- 编辑器 -->
					<el-form-item prop="copyreader">
						<div class="subject">
							<span class="xingxing">*</span>
							节目内容
							<!-- <span class="referrals">( 推荐：视频体积小于100M，图片体积小于1M。 )</span> -->
						</div>
						<div style="border: 1px solid #ccc; width:100%">
							<Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef"
								:defaultConfig="toolbarConfig" :mode="mode" />

							<Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml"
								:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
			<!-- 所属工会选择 -->
			<div>
				<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
					<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
				</s3-layer>
			</div>
		</el-card>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted,
		reactive,
		onActivated,
	} from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import {
		Plus,
	} from '@element-plus/icons-vue'
	import * as imageConversion from 'image-conversion'
	import {
		ElMessage
	} from "element-plus";
	import {
		editProgram,
		ZClist,
		infoProgram,
	} from "../../utils/api"
	import {
		useRouter,
		useRoute
	} from "vue-router";
	// import { Pictures } from '../../utils/api'
	import {
		useStore
	} from "vuex";
	import qs from 'qs'
	import moments from 'moment'
	export default {
		name: "jmedit",
		components: {
			Editor,
			Toolbar,
			Plus,
		},
		setup() {
			//获取列表页传来的id
			const route = useRoute()
			let loading = ref(true)
			let spChildIndex = ref(0)
			// 默认显示已上传文件
			let approve = ref([{}])
			//流程详情获取
			let infoPrograms = () => {
				// console.log(sessionStorage.getItem('processIds'))
				let data = {
					programId: sessionStorage.getItem('programId')
				}
				// var userNames = ref([])
				const id = ref([])
				infoProgram(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						// 活动名称
						form.title = res.data.data.programInfo.title
						//部门名称
						form.department = res.data.data.programInfo.department
						//是否发布
						if (res.data.data.programInfo.isEnable == 1) {
							form.isEnable = '发布'
						} else if (res.data.data.programInfo.isEnable == 2) {
							form.isEnable = '未发布'
						}
						//排序
						form.sort = res.data.data.programInfo.sort
						//编辑器内容
						valueHtml.value = res.data.data.programInfo.content

					}
				})
			}
			// const route = useRoute();
			// 清空图片
			const qingkong = ref('')
			let data = ref(null);
			// 按钮加载
			const ConfirmLoading = ref(false)

			// 上传图片
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 获取上传图片成功地址
			let location = ref('')
			const succ = (res) => {
				location.value = res.data
				console.log(res)
			}
			// 自定义上传
			const httpreqst = (param) => {
				console.log(param)
				console.log(param.file.name)
				data.value = {
					imageFile: param.file.namefileList
				}
				// Pictures(data).then((res)=>{
				//   console.log(res)
				// })
			}
			const styA = ref(0)
			const styD = ref(1)
			const change = (res, fileList) => {
				// console.log(res)
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 930).then((res) => {
						resolve(res);
						// data.value = File.name
					});
				})
			}
			// 上传成功，获取返回的图片地址
			const handleUpImage = (res) => {
				location.value = res.data
				console.log(res, '成功')
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				styA.value = 0
				styD.value = 0
				form.breviary = ''
			}
			const Remove = (file) => {
				console.log(file)
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			// 开始时间
			let initiation = ref('')
			// 结束时间
			let terminate = ref('')
			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
				new Date(2000, 2, 1, 23, 59, 59),

			]
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					loading.value = true
					infoPrograms()
				}
			})
			// 添加按钮是否显示
			// let styD = ref(0)
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 提交 按钮
			const router = useRouter();
			const onSubmit = () => {

				if (valueHtml.value == '<p><br></p>') {
					form.copyreader = ''
				} else {
					form.copyreader = valueHtml.value
				}
				//是否发布转换
				let isEnables = ref(null)
				if (form.isEnable == '发布') {
					isEnables.value = 1
				} else if (form.isEnable == '未发布') {
					isEnables.value = 2
				} else if (form.isEnable == 1) {
					isEnables.value = 1
				} else if (form.isEnable == 2) {
					isEnables.value = 2
				}
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					let data = {
						programId: sessionStorage.getItem('programId'),
						// 活动名称
						title: form.title,
						//部门名称
						department:form.department,
						//排序
						sort: form.sort,
						//是否发布
						isEnable: isEnables.value,
						// 编辑器内容
						content: form.copyreader,

					}
					if (valid == true) {
						ConfirmLoading.value = true
						editProgram(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'jmdetail',
										params: {
											UserId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)
							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					console.log(form)
				})
			}
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('')

			// 模拟 ajax 异步获取内容
			onMounted(() => {
				infoPrograms()

			})

			const toolbarConfig = {
				toolbarKeys: [
					'bold', // 加粗
					'italic', // 斜体
					'through', // 删除线
					'underline', // 下划线
					'bulletedList', // 无序列表
					'numberedList', // 有序列表
					'color', // 文字颜色
					'fontSize', // 字体大小
					'lineHeight', // 行高
					'uploadImage', // 上传图片
					'delIndent', // 缩进
					'indent', // 增进
					'deleteImage', //删除图片
					'justifyCenter', // 居中对齐
					'justifyJustify', // 两端对齐
					'justifyLeft', // 左对齐
					'justifyRight', // 右对齐
					'undo', // 撤销
					'redo', // 重做
					'clearStyle', // 清除格式
					'uploadVideo',
				],
				excludeKeys: [
					'insertImage', // 网络图片
					'bgColor', // 背景色
					'blockquote', // 引用
					'codeBlock', // 代码段
					'emotion', // 表情
					'fontFamily', // 字体
					'headerSelect', // 标题 
					'fullScreen', // 全屏
				],
			}
			// 富文本上传图片地址
			// let fils=ref('')
			// placeholder: '请输入内容...',
			const editorConfig = {
				MENU_CONF: {}
			}
			// 视频上传
			editorConfig.MENU_CONF['uploadVideo'] = {
				// 上传视频的配置
				// 接口
				server: '/admin/api/v1/activity/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				// 限制上传视频大小
				maxFileSize: 104857600, // 100M
				//上传字段 
				fieldName: 'imageFile',
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 判断视频是否大于100M
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 104857600) {
						return true
					} else if (isLt2M > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError(file, res) {
					console.log(file)
					console.log(res)
					if (file.size > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
					} else {
						ElMessage({
							message: '上传失败，请稍后再试',
							type: "warning",
							center: true,
						});
					}
				},
			}


			// 图片上传
			editorConfig.MENU_CONF['uploadImage'] = {
				// 上传图片的配置 
				// 接口
				server: '/admin/api/v1/activity/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				maxFileSize: 1 * 1024 * 1024, // 1M
				//上传字段 
				fieldName: 'imageFile',
				// meta: {
				//   imageFile:fils
				// },
				// 最多可上传几个文件，默认为 100
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 压缩图片
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 1048576) {
						return true
					} else if (isLt2M > 1048576) {
						ElMessage({
							message: '图片大小不能超过1MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError() {
					ElMessage({
						message: '图片大小不能超过1MB,请重新上传',
						type: "warning",
						center: true,
					});
				},
			}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})

			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
				// console.log(editor)
			}
			const visible = ref(false);
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				name: "",
				title: "",
				value1: "",
				type: "",
				copyreader: "",
				choose: "",
				isEnable: "",
				maxMembersNumber: "",
				breviary: "",
			})
			// 验证表单提示
			const rules = {
				title: [{
					required: true,
					message: "请填写节目名称",
					trigger: "blur"
				}, ],
				department: [{
					required: true,
					message: "请填写节目名称",
					trigger: "blur"
				}, ],
				isEnable: [{
					required: true,
					message: "请选择是否发布",
					trigger: "change"
				}, ],
				copyreader: [{
					required: true,
					message: "请填写节目内容",
					trigger: "change"
				}, ],
			}
			return {
				qingkong,
				header,
				succ,
				data,
				// 按钮加载
				ConfirmLoading,
				// 上传图片
				httpreqst,
				dialogVisible,
				dialogImageUrl,
				onpreview,
				beforeremove,
				Remove,
				styA,
				styD,
				change,
				beforeImageUpload,
				// handlePictureCardPreview,
				// 编辑器
				onSubmit,
				editorRef,
				valueHtml,
				mode: 'default',
				toolbarConfig,
				editorConfig,
				handleCreated,
				// 标题验证
				ruleFormRef,
				form,
				rules,
				visible,
				texture,
				defaultProps,
				dendrogram,
				defaultTime2,
				approve,
				value1: [],
				handleUpImage,
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 115px !important;
		font-weight: bold;
	}
</style>